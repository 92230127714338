import './CardCheckContact.css';

const CardCheckContact = ({icon , placeholder, handleChecked , field,value}) => {

    const handleCheckedValue = (e)=>{

        handleChecked(field,!value)
    }

  return (
    <div className='parent-card__check' onClick={handleCheckedValue} >

      <div className='parent-card__check-icon'>
      {icon}
      <span>
        {placeholder}
      </span>
      </div>

      <div className='parent-card__check-input'>

      <label htmlFor="custom-checkbox" className="form-control">
        <input className="parent-finish-checkbox" checked={value} id="custom-checkbox"  type="checkbox"  name="checkbox-checked" style={{accentColor:'#fcc400 !important'}}  />
      </label>

      </div>

    </div>
  )
}

export default CardCheckContact
